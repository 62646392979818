import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import React from 'react'
import SitePost from '../components/SitePost'
import SitePage from '../components/SitePage'
import SiteContainedPage from '../components/SiteContainedPage'
import { graphql } from "gatsby"

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this, 'props.data.post')
    const site = get(this, 'props.data.site')
    const layout = get(post, 'frontmatter.layout')
    const title = get(post, 'frontmatter.title')
    const siteTitle = get(site, 'meta.title')

    let template = ''
    if (layout == 'post') {
      template = <SitePost data={post} site={site} isIndex={false} />
    } else if (layout == 'page') {
      template = <SiteContainedPage data={post} site={site} isIndex={false} />
    } else {
      template = <SitePage {...this.props} />
    }
    return (
      <Layout location={this.props.location}>
        <Helmet
          title={`${title} | ${siteTitle}`}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'meta.twitter')}` },
            { property: 'og:title', content: get(post, 'frontmatter.title') },
            { property: 'og:type', content: 'article' },
            {
              property: 'og:description',
              content: get(post, 'excerpt'),
            },
            {
              property: 'og:url',
              content: get(site, 'meta.url') + get(post, 'frontmatter.path'),
            },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/opengraph.jpg`,
            },
          ]}
        />
        {template}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      excerpt
      frontmatter {
        layout
        title
        path
        categories
        date(formatString: "DD.MM.YYYY")
        type
        image {
          childImageSharp {
            resize(width: 400) {
              src
            }
          }
        }
      }
    }
  }
`
